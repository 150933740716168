import React from "react";
import styled from "styled-components";
import SideBarSocialIcons from "./sidebar-social-icons";

const Social = styled.div`
  display: flex;
  height: 12rem;
  width: 10rem;
  position: ${(props) => (props.newsPage ? "absolute" : "relative")};
  top: ${(props) => (props.newsPage ? "25rem" : "0")};

  .share-this {
    transform: rotate(-90deg);
    top: 50%;
    font-size: 1rem;
    color: var(--blue);
    font-weight: bold;
    position: absolute;
    margin: 0;
    left: -10px;
  }
`;

const SideBarSocial = ({ newsPage }) => {
  return (
    <Social newsPage={newsPage}>
      <p className="share-this">Share this website</p>
      <SideBarSocialIcons />
    </Social>
  );
};

export default SideBarSocial;
