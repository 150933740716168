import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article, author }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultSiteName,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultAuthor
  } = site.siteMetadata

  const seo = {
    siteName: defaultSiteName,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    author: author || defaultAuthor,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content={seo.author} />
      <meta property="og:site_name" content={seo.siteName} />
      <meta property="og:locale" content="en_US" />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}
      {(!article ? true : null) && <meta property="og:type" content="website" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Website",
            "name": "Nearshore Portal",
            "url": "https://nearshore.pt/",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://nearshore.pt/"
            },
            "image": "https://nearshore.pt/static/image-for-shore@3x-5fa323b3acb0520390457d4932f88488.webp",
            "publisher": {
              "@type": "Organization",
              "name": "Caixa Mágica",
              "logo": {
                "@type": "ImageObject",
                "url": "https://caixamagica.pt/sites/default/files/cms_images/index/cms-box-opacity.png"
              }
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultSiteName: siteName
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultAuthor: author
      }
    }
  }
`